const defaultPFOnboardingOptions = {
  name: "Novo template PF",
  description: "Edite este template para ficar com a cara da sua empresa",

  type: "PF",
  domain: process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN,
  background: "#EEEEEE",
  font: { name: "Roboto", source: "" },
  documentTypes: [{ type: "CNH" }, { type: "RG" }],
  terms: [
    {
      name: "Termos de Uso e Políticas de Privacidade",
      label:
        "Sim, quero verificar minha identidade. Eu li e aceito os [termos de uso e políticas de privacidade](https://www.caf.io/politicas/termos-e-condicoes-de-uso).",

      required: true,
    },
  ],
  configurations: {
    captureAcceptedTermsData: true,
    verifyBrowserCompatibility: true,
    dataReload: true,
  },
  steps: [
    {
      name: "WELCOME-0",
    },
    {
      name: "DOCUMENT_TYPE-0",
    },
    {
      name: "CAMERA_ACCESS-0",
    },
    {
      name: "INSTRUCTIONS-0",
      heading: "Prepare sua verificação",
      tips: [
        {
          icon: "sidebar",
          text: "Tire a capinha do seu documento e coloque sobre uma superfície plana.",
        },
        {
          icon: "faceID",
          text: "Posicione seu documento na área indicada, tire a foto da frente, depois do verso e, se possível, não posicione seu dedo sobre o documento.",
        },
        {
          icon: "sun",
          text: "Para garantir a qualidade procure um lugar iluminado.",
        },
        {
          icon: "glasses",
          text: "Não use óculos ou bonés.",
        },
      ],
    },
    {
      name: "DD-0",
      preview: true,
    },
    {
      name: "PFL-0",
      preview: true,
    },
    {
      name: "DATA_CONFIRMATION-0",
      fields: [
        {
          field: "name",
        },
        {
          field: "cpf",
        },
        {
          field: "birthdate",
        },
        {
          field: "mother_name",
        },
      ],
    },
    {
      name: "DONE-0",
    },
  ],
  colors: {
    primary: "#666666",
    secondary: "#666666",
    illustrations: "#666666",
    headings: "#1f2937",
    subheading: "#1f2937",
  },
  footer: { text: "", textColor: "#1f2937", logo: "", background: "" },
  internationalization: {
    language: "pt-BR",
  },
};
Object.freeze(defaultPFOnboardingOptions);

const defaultPJOnboardingOptions = {
  name: "Novo template PJ",
  description: "Edite este template para ficar com a cara da sua empresa",

  type: "PJ",
  domain: process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN,
  background: "#EEEEEE",
  font: { name: "Roboto", source: "" },
  documentTypes: [{ type: "CNH" }, { type: "RG" }],
  terms: [
    {
      name: "Termos de Uso e Políticas de Privacidade",
      label:
        "Sim, quero verificar minha identidade. Eu li e aceito os [termos de uso e políticas de privacidade](https://www.caf.io/politicas/termos-e-condicoes-de-uso).",
      required: true,
    },
  ],
  configurations: {
    captureAcceptedTermsData: true,
    verifyBrowserCompatibility: true,
    dataReload: true,
  },
  steps: [
    {
      name: "WELCOME-0",
      heading: "Nova Conta PJ",
      subheading: "Tenha em mãos o seu RG ou CNH.",
      illustration: "illustration_people",
    },
    {
      name: "COMPANY_DATA-0",
      fields: [
        {
          field: "official_name",
        },
        {
          field: "fantasy_name",
          required: true,
        },
        {
          field: "phone_number",
        },
      ],
    },
    {
      name: "BUSINESS_ADDRESS-0",
    },
    {
      name: "UPLOAD_DOCUMENTS-0",
      onboardingType: "PJ",
      fields: [
        {
          type: "COMPANY_SOCIAL_CONTRACT",
          title: "Contrato social",
          required: true,
          extensions: ["pdf", "png", "jpeg", "jpg"],
        },
        {
          type: "COMPANY_FINANCIAL_STATEMENTS",
          title: "Balancete financeiro",
          required: false,
          extensions: ["pdf", "png", "jpeg", "jpg"],
        },
        {
          type: "COMPANY_DRE",
          title: "DRE",
          required: false,
          extensions: ["pdf", "png", "jpeg", "jpg"],
        },
        {
          type: "OTHER",
          title: "Outros",
          required: false,
          extensions: ["pdf", "png", "jpeg", "jpg"],
        },
      ],
    },
    {
      name: "INCOME-0",
    },
    {
      name: "FLOW_CHOICE-0",
      options: [
        {
          text: "Sou funcionário da empresa",
          flow: "FUNCIONARIO",
        },
        {
          text: "Sou sócio da empresa",
          flow: "SOCIO",
        },
      ],
    },
    {
      name: "QSA-0",
      flow: ["SOCIO"],
    },
    {
      name: "CUSTOM-0",
      flow: ["SOCIO"],
      heading: "Olá {{personName}}, vamos conhecer você",
      subheading: "Tenha em mãos o seu RG ou CNH.",
      illustration: "illustration_onboarding",
    },
    {
      name: "DOCUMENT_TYPE-0",
      flow: ["SOCIO"],
    },
    {
      name: "CAMERA_ACCESS-0",
      flow: ["SOCIO"],
    },
    {
      name: "INSTRUCTIONS-0",
      flow: ["SOCIO"],
      tips: [
        {
          icon: "sidebar",
          text: "Tire a capinha do seu documento e coloque sobre uma superfície plana.",
        },
        {
          icon: "faceID",
          text: "Posicione seu documento na área indicada, tire a foto da frente, depois do verso e, se possível, não posicione seu dedo sobre o documento.",
        },
        {
          icon: "sun",
          text: "Para garantir a qualidade procure um lugar iluminado.",
        },
        {
          icon: "glasses",
          text: "Não use óculos ou bonés.",
        },
      ],
    },
    {
      name: "DD-0",
      flow: ["SOCIO"],
      preview: true,
    },
    {
      name: "PFL-0",
      flow: ["SOCIO"],
      preview: true,
    },
    {
      name: "DATA_CONFIRMATION-0",
      flow: ["SOCIO"],
      fields: [
        {
          field: "name",
        },
        {
          field: "cpf",
        },
        {
          field: "email",
        },
        {
          field: "phone_number",
        },
      ],
    },
    {
      name: "EMPLOYEE_DATA-0",
      flow: ["FUNCIONARIO"],
      fields: [
        {
          field: "name",
        },
        {
          field: "occupation",
          options: ["Administrativo", "Comercial", "RH", "Tecnologia", "Outro"],
        },
        {
          field: "email",
        },
        {
          field: "phone_number",
        },
      ],
    },
    {
      name: "QSA-1",
      flow: ["FUNCIONARIO"],
      manual: "true",
    },
    {
      name: "DONE-0",
      flow: ["FUNCIONARIO", "SOCIO"],
      heading: "Cadastro realizado com sucesso!",
      subheading:
        "Seu cadastro está completo e já foi enviado para análise! Assim que todos os sócios concluírem suas etapas de onboarding, você receberá um e-mail com seu login e senha de acesso.",
      illustration: "illustration_success",
    },
  ],
  colors: {
    primary: "#666666",
    secondary: "#666666",
    illustrations: "#666666",
    headings: "#1f2937",
    subheading: "#1f2937",
  },
  footer: { text: "", textColor: "#1f2937", logo: "", background: "" },
  internationalization: {
    language: "pt-BR",
  },
};
Object.freeze(defaultPJOnboardingOptions);

export { defaultPFOnboardingOptions, defaultPJOnboardingOptions };
