import { onboardingInputTypes } from "@components/Inputs/OnboardingInput";
import { previewModes } from "@components/ObjectListEditor";
import Onboarding from "@package/steps";
import { customizableElementsEditorValidations } from "@utils/rules-validations";
import { onboardingPropsParserTranslator } from "@utils/translator";

const { defaultOnboardingValues } = Onboarding;

const I18N_BASE_PATH = "src.components.elementEditor.customizableElements";

const {
  shouldRenderIfStepSendDocumentTypeIsUsedOnFlow,
  shouldNotRenderErrorsInBusinessAddressWhenKYBTheme,
  shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow,
  shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow,
} = customizableElementsEditorValidations();

export const RULES = {
  "STEP_CUSTOM-LABELS-DD-WHEN-SEND-DOCUMENT-TYPE": [
    {
      key: "shouldRenderIfStepSendDocumentTypeIsUsedOnFlow",
      onValid: shouldRenderIfStepSendDocumentTypeIsUsedOnFlow,
    },
  ],
  SHOULD_RENDER_PREVIEW_PDF_SWITCH: [
    {
      key: "shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow",
      onValid: shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow,
    },
  ],
  SHOULD_NOT_RENDER_ERRORS_IN_BUSINESS_ADDRESS_WHEN_KYB_THEME: [
    {
      key: "shouldNotRenderErrorsInBusinessAddressWhenKYBTheme",
      onValid: shouldNotRenderErrorsInBusinessAddressWhenKYBTheme,
    },
  ],
  SHOULD_RENDER_DOCUMENT_AUTOMATIC_CAPTURE_SETTINGS: [
    {
      key: "shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow",
      onValid: shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow,
    },
  ],
};

const renderCustomizableElements = (language) => {
  const customizableElements = {
    "NAVIGATION_STEPS-PROGRESS": {
      name: `${I18N_BASE_PATH}.NAVIGATION_STEPS-PROGRESS.name`,
      // => Barra de progresso
      customizables: [
        {
          label: `${I18N_BASE_PATH}.NAVIGATION_STEPS-PROGRESS.customizables.progressBarColor.label`,
          // => Cor
          type: onboardingInputTypes.COLOR,
          defaultProperty: "colors.secondary",
          property: "colors.progressbar",
        },
      ],
    },
    "NAVIGATION_FORWARD-BUTTON": {
      name: `${I18N_BASE_PATH}.NAVIGATION_FORWARD-BUTTON.name`,
      // => Botão de avançar
      customizables: [
        {
          label: `${I18N_BASE_PATH}.general.text`,
          // => Texto
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "texts.backButton",
          defaultValue: `${I18N_BASE_PATH}.NAVIGATION_FORWARD-BUTTON.customizables.text.defaultValue`,
          // => Continuar
        },
        {
          label: `${I18N_BASE_PATH}.NAVIGATION_FORWARD-BUTTON.customizables.primaryColor.label`,
          // => Cor (primária)
          type: onboardingInputTypes.COLOR,
          property: "colors.primary",
        },
      ],
    },
    "NAVIGATION_BACKWARD-BUTTON": {
      name: `${I18N_BASE_PATH}.NAVIGATION_BACKWARD-BUTTON.name`,
      // => Botão de retroceder
      customizables: [
        {
          label: `${I18N_BASE_PATH}.general.background`,
          // => Fundo
          type: onboardingInputTypes.COLOR,
          property: "colors.backButton",
          defaultValue: "#EEEEEE",
        },
        {
          label: `${I18N_BASE_PATH}.general.icon`,
          // => Ícone
          type: onboardingInputTypes.COLOR,
          property: "colors.backButtonIcon",
          defaultValue: "#666",
        },
        {
          label: `${I18N_BASE_PATH}.NAVIGATION_BACKWARD-BUTTON.customizables.backButtonBorderColor.label`,
          // => Borda
          type: onboardingInputTypes.COLOR,
          property: "colors.backButtonBorder",
          defaultValue: "#FFFFFF00",
        },
      ],
    },
    MAIN_LOGO: {
      name: `${I18N_BASE_PATH}.MAIN_LOGO.name`,
      // => Logo principal
      customizables: [
        {
          label: `${I18N_BASE_PATH}.MAIN_LOGO.customizables.label`,
          // => Imagem
          type: onboardingInputTypes.IMAGE,
          property: "logo",
        },
      ],
    },
    TAKE_SELFIE_BUTTON: {
      name: `${I18N_BASE_PATH}.TAKE_SELFIE_BUTTON.name`,
      customizables: [
        {
          label: `${I18N_BASE_PATH}.TAKE_SELFIE_BUTTON.customizables.buttonText.label`,
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "takeSelfieButtonText",
          defaultValue: `${I18N_BASE_PATH}.TAKE_SELFIE_BUTTON.customizables.buttonText.defaultValue`,
        },
        {
          label: `${I18N_BASE_PATH}.TAKE_SELFIE_BUTTON.customizables.buttonTextColor.label`,
          type: onboardingInputTypes.COLOR,
          property: "buttonTextColor",
          defaultValue: "#FFFFFF",
        },
        {
          label: `${I18N_BASE_PATH}.TAKE_SELFIE_BUTTON.customizables.buttonBackgroundColor.label`,
          type: onboardingInputTypes.COLOR,
          property: "buttonBackgroundColor",
          defaultValue: "#666666",
        },
        {
          label: `${I18N_BASE_PATH}.TAKE_SELFIE_BUTTON.customizables.buttonBorderColor.label`,
          type: onboardingInputTypes.COLOR,
          property: "buttonBorderColor",
          defaultValue: "#666666",
        },
      ],
    },
    CAMERA_PERMISSIONS_BUTTON: {
      name: `${I18N_BASE_PATH}.CAMERA_PERMISSIONS_BUTTON.name`,
      // => "Botão para acessar a câmera"
      customizables: [
        {
          label: `${I18N_BASE_PATH}.CAMERA_PERMISSIONS_BUTTON.customizables.label`,
          // => Botão
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "buttonPermissionText",
          defaultValue: `${I18N_BASE_PATH}.CAMERA_PERMISSIONS_BUTTON.customizables.defaultValue`,
          // => Habilitar acesso
        },
      ],
    },
    FORM_DISPATCH_ADDRESS: {
      name: `${I18N_BASE_PATH}.general.formAddress.name`,
      // => Formulário de endereço
      customizables: [
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.zipCode.label`,
          // => CEP
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "zipCode",
        },
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.state.label`,
          // => Estado
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "state",
        },
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.city.label`,
          // => Cidade
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "city",
        },
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.street.label`,
          // => Rua
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "street",
        },
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.neighborhood.label`,
          // => Bairro
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "neighborhood",
        },
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.number.label`,
          // => Número
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "number",
        },
        {
          label: `${I18N_BASE_PATH}.general.formAddress.customizables.complement.label`,
          // => Complemento
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "complement",
        },
      ],
    },
    MAIN_FOOTER: {
      name: `${I18N_BASE_PATH}.MAIN_FOOTER.name`,
      // => Rodapé
      customizables: [
        {
          label: `${I18N_BASE_PATH}.general.text`,
          // => Texto
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "footer.text",
        },
        {
          label: `${I18N_BASE_PATH}.MAIN_FOOTER.customizables.footerTextColor.label`,
          // => Cor do texto
          type: onboardingInputTypes.COLOR,
          property: "footer.textColor",
        },
        {
          label: `${I18N_BASE_PATH}.MAIN_FOOTER.customizables.footerLogo.label`,
          // => Logo
          type: onboardingInputTypes.IMAGE,
          property: "footer.logo",
        },
        {
          label: `${I18N_BASE_PATH}.general.background`,
          // => Fundo
          tooltip: `${I18N_BASE_PATH}.MAIN_FOOTER.customizables.footerBackground.tooltip`,
          // => A imagem deve ter altura de 62px
          type: onboardingInputTypes.IMAGE,
          property: "footer.background",
        },
      ],
    },
    STEP_HEADING: {
      customizables: [
        {
          property: "heading",
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          rows: 2,
        },
      ],
    },
    STEP_SUBHEADING: {
      customizables: [
        {
          property: "subheading",
          label: `${I18N_BASE_PATH}.general.subtitle`,
          // => Subtítulo
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          rows: 3,
        },
      ],
    },
    CAMERA_PERMISSIONS_SWITCH: {
      name: `${I18N_BASE_PATH}.CAMERA_PERMISSIONS_SWITCH.name`,
      // => Localização do usuário
      customizables: [
        {
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.CAMERA_PERMISSIONS_SWITCH.customizables.shouldGetUserLocation.label`,
          // => Solicitar localização do usuário
          property: "shouldGetUserLocation",
        },
      ],
    },
    STEP_ILLUSTRATION: {
      customizables: [
        {
          property: "illustration",
          label: `${I18N_BASE_PATH}.general.illustration`,
          // => Ilustração
          type: onboardingInputTypes.IMAGE,
        },
      ],
    },
    // ERRORS
    STEP_ERROR_HEADING: {
      name: `${I18N_BASE_PATH}.STEP_ERROR_HEADING.name`,
      // => Título do erro
      customizables: [
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          isErrorProperty: true,
          property: "heading",
        },
      ],
    },
    STEP_ERROR_SUBHEADING: {
      name: `${I18N_BASE_PATH}.STEP_ERROR_SUBHEADING.name`,
      // => Subtítulo do erro
      customizables: [
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          isErrorProperty: true,
          property: "subheading",
        },
      ],
    },
    STEP_ERROR_ILLUSTRATION: {
      name: `${I18N_BASE_PATH}.STEP_ERROR_ILLUSTRATION.name`,
      // => Ilustração do erro
      customizables: [
        {
          type: onboardingInputTypes.IMAGE,
          isErrorProperty: true,
          property: "illustration",
        },
      ],
    },
    STEP_ERROR_TEXT: {
      name: `${I18N_BASE_PATH}.STEP_ERROR_TEXT.name`,
      // => Texto do erro
      customizables: [
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          isErrorProperty: true,
          property: "text",
        },
      ],
    },
    // SDK CUSTOM LABELS
    "STEP_CUSTOM-LABELS-PFL": {
      name: `${I18N_BASE_PATH}.general.sdk.customMessage.name`,
      // => Mensagens personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.TEXT,
          property: "sdkOptions.initialize.textSettings.title",
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.loadingMessage`,
          // => Mensagem de carregamento
          property: "sdkOptions.initialize.textSettings.messages.processMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-PFL.customizables.sdkProcessMessage.tooltip`,
          // => Mensagem de carregamento enquanto o PFL captura o rosto e realiza a prova de vida
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-PFL.customizables.sdkIsNotAliveMessage.label`,
          // => Erro no Liveness
          property: "sdkOptions.initialize.textSettings.messages.isNotAliveMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-PFL.customizables.sdkIsNotAliveMessage.tooltip`,
          // => Mensagem de erro caso o PFL não consiga capturar o rosto. Utilize a tag <br/> para quebrar uma linha
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorLabel`,
          // => Erro genérico
          property: "sdkOptions.initialize.textSettings.messages.captureFailedMessage",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorTooltip`,
          // => Mensagem de erro caso aconteça algum erro não mapeado
          rows: 2,
        },
      ],
    },
    // PFL SETTINGS
    "STEP_CUSTOM-CONFIGURATIONS-PFL": {
      name: `${I18N_BASE_PATH}.general.sdk.customSettings.name`,
      // => Configurações personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.label`,
          // => Captura automática
          property: "sdkOptions.capture.captureSettings.mode",
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.manual`,
              // => Manual
              value: "manual",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.automatic`,
              // => Automática
              value: "automatic",
            },
          ],
          defaultValue: "manual",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.label`,
          // => Tempo de captura automática
          property: "sdkOptions.capture.captureSettings.automaticCaptureTimeoutInSeconds",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.tooltip`,
          // => Tempo de espera, em segundos, para habilitar a captura manual
        },
      ],
    },
    // PFL IPROOV SETTINGS
    "STEP_CUSTOM-CONFIGURATIONS-PFL-IPROOV": {
      name: `${I18N_BASE_PATH}.general.sdk.customSettings.name`,
      // => Configurações personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.label`,
          // => Filtro de Câmera
          property: "sdkOptions.filter",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.tooltip`,
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.clear`,
              // => Sem Filtro
              value: "clear",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.classic`,
              // => Clássico
              value: "classic",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.shaded`,
              // => Sombreado
              value: "shaded",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.vibrant`,
              // => Vibrante
              value: "vibrant",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.blur`,
              // => Desfoque
              value: "blur",
            },
          ],
          defaultValue: "clear",
          labelKey: "label",
          valueKey: "value",
        },
      ],
    },
    // FA SETTINGS
    "STEP_CUSTOM-LABELS-FA": {
      name: `${I18N_BASE_PATH}.general.sdk.customMessage.name`,
      // => Mensagens personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-FA.customizables.sdkIsMatchFalseMessage.label`,
          // => Mensagem de falha de autenticação
          property: "sdkOptions.initialize.textSettings.messages.isNotMatchMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-FA.customizables.sdkIsMatchFalseMessage.tooltip`,
          rows: 2,
        },
      ],
    },
    "STEP_CUSTOM-CONFIGURATIONS-FA": {
      name: `${I18N_BASE_PATH}.general.sdk.customSettings.name`,
      // => Configurações personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.label`,
          // => Captura automática
          property: "sdkOptions.capture.captureSettings.mode",
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.manual`,
              // => Manual
              value: "manual",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.automatic`,
              // => Automática
              value: "automatic",
            },
          ],
          defaultValue: "manual",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.label`,
          // => Tempo de captura automática
          property: "sdkOptions.capture.captureSettings.automaticCaptureTimeoutInSeconds",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.tooltip`,
          // => Tempo de espera, em segundos, para habilitar a captura manual
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.totalAttempts.label`,
          // => Número máximo de tentativas
          property: "sdkOptions.capture.captureSettings.totalAttempts",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.totalAttempts.tooltip`,
          // => Número máximo de vezes para autenticar a face, após atingido o usuário será direcionado para a tela final
          defaultValue: 5,
        },
      ],
    },
    // FA IPROOV
    "STEP_CUSTOM-CONFIGURATIONS-FA-IPROOV": {
      name: `${I18N_BASE_PATH}.general.sdk.customSettings.name`,
      // => Configurações personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.label`,
          // => Filtro de Câmera
          property: "sdkOptions.filter",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.tooltip`,
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.clear`,
              // => Sem Filtro
              value: "clear",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.classic`,
              // => Clássico
              value: "classic",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.shaded`,
              // => Sombreado
              value: "shaded",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.vibrant`,
              // => Vibrante
              value: "vibrant",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.cameraFilter.options.blur`,
              // => Desfoque
              value: "blur",
            },
          ],
          defaultValue: "clear",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.totalAttempts.labelLiveness`,
          // => Número máximo de tentativas
          property: "sdkOptions.totalAttempts.liveness",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.totalAttempts.tooltip`,
          // => Número máximo de vezes para autenticar a face, após atingido o usuário será direcionado para a tela final
          defaultValue: 0,
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.totalAttempts.labelFaceAuthenticaton`,
          // => Número máximo de tentativas
          property: "sdkOptions.totalAttempts.faceAuthentication",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.totalAttempts.tooltip`,
          // => Número máximo de vezes para autenticar a face, após atingido o usuário será direcionado para a tela final
          defaultValue: 0,
        },
      ],
    },
    // DD CUSTOM LABELS
    "STEP_CUSTOM-LABELS-DD": {
      name: `${I18N_BASE_PATH}.general.sdk.customMessage.name`,
      // => Mensagens personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.loadingMessage`,
          // => Mensagem de carregamento
          property: "sdkOptions.initialize.textSettings.messages.processMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkProcessMessage.tooltip`,
          // => Mensagem de carregamento enquanto o DD processa o documento
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkWrongDocumentMessage.label`,
          // => Erro no tipo de documento
          property: "sdkOptions.initialize.textSettings.messages.wrongDocumentMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkWrongDocumentMessage.tooltip`,
          // => Mensagem de erro caso o usuário esteja tentando capturar um documento que não foi o selecionado na tela 'Tipo de documento'
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkBothWrongSideMessage.label`,
          // => Captura de documento aberto
          property: "sdkOptions.initialize.textSettings.messages.bothWrongSideMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkBothWrongSideMessage.tooltip`,
          // => Mensagem de erro caso o usuário esteja tentando capturar o documento aberto
          rows: 3,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkWrongSideMessage.label`,
          // => Lado incorreto do documento
          property: "sdkOptions.initialize.textSettings.messages.wrongSideMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkWrongSideMessage.tooltip`,
          // => Mensagem de erro caso o usuário esteja tentando capturar o lado invertido do documento
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkLowQualityMessage.label`,
          // => Baixa qualidade na captura
          property: "sdkOptions.initialize.textSettings.messages.lowQualityMessage",
          tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-LABELS-DD.customizables.sdkLowQualityMessage.tooltip`,
          // => Mensagem de erro caso a captura do documento não fique em boa qualidade
          rows: 4,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorLabel`,
          // => Erro genérico
          property: "sdkOptions.initialize.textSettings.messages.captureFailedMessage",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorTooltip`,
          // => Mensagem de erro caso aconteça algum erro não mapeado
          rows: 2,
        },
      ],
    },
    // DD CUSTOM UPLOAD LABELS
    "STEP_CUSTOM-LABELS-DD-WHEN-SEND-DOCUMENT-TYPE": {
      name: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.name`,
      // => Mensagens personalizadas (Upload)
      panel: true,
      isRootProperty: false,
      rules: RULES["STEP_CUSTOM-LABELS-DD-WHEN-SEND-DOCUMENT-TYPE"],
      // TODO: ADD traduções
      customizables: [
        {
          type: onboardingInputTypes.DROP_DOWN,
          name: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.name`,
          // => Títulos
          options: [
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.subtitleVariables.primary.name`,
              // Título 1
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessTitleFrontSide",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.subtitleVariables.primary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 2,
            },
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.subtitleVariables.secondary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessTitleBackSide",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.subtitleVariables.secondary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 2,
            },
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.subtitleVariables.tertiary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessTitleBothSide",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.titleOptions.subtitleVariables.tertiary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 2,
            },
          ],
        },
        {
          type: onboardingInputTypes.DROP_DOWN,
          name: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.name`,
          options: [
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.subtitleVariables.primary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessMessageFrontSide",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.subtitleVariables.primary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 4,
            },
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.subtitleVariables.secondary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessMessageBackSide",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.subtitleVariables.secondary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 4,
            },
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.subtitleVariables.tertiary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessMessageBothSide",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.messageOptions.subtitleVariables.tertiary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 4,
            },
          ],
        },
        {
          type: onboardingInputTypes.DROP_DOWN,
          name: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.buttonOptions.name`,
          options: [
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.buttonOptions.subtitleVariables.primary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessButtonPrimary",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.buttonOptions.subtitleVariables.primary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 2,
            },
            {
              type: onboardingInputTypes.TEXT_AREA,
              label: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.buttonOptions.subtitleVariables.secondary.name`,
              // => TODO: add brazilian text here: label
              property: "sdkOptions.initialize.textSettings.uploadMessages.allowAccessButtonSecondary",
              tooltip: `${I18N_BASE_PATH}.general.sdk.customUploadMessages.buttonOptions.subtitleVariables.secondary.tooltip`,
              // => TODO: add brazilian text here: tooltip
              rows: 2,
            },
          ],
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Descrição",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.invalidFormatTitle",
          tooltip: "descrição do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Descrição",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.invalidFormatMessage",
          tooltip: "descrição do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 3,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Botão de envio",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.tryAgainButton",
          tooltip: "botão do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Descrição",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.sendFileTitle",
          tooltip: "descrição do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Botão de envio",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.errorTitle",
          tooltip: "botão do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Descrição",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.errorMessage",
          tooltip: "descrição do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: "Botão de envio",
          // => TODO: add brazilian text here: label
          property: "sdkOptions.initialize.textSettings.uploadMessages.successTitle",
          tooltip: "botão do upload",
          // => TODO: add brazilian text here: tooltip
          rows: 2,
        },
      ],
    },
    "STEP_CUSTOM-CONFIGURATIONS-DD": {
      name: `${I18N_BASE_PATH}.general.sdk.customSettings.name`,
      rules: RULES["SHOULD_RENDER_DOCUMENT_AUTOMATIC_CAPTURE_SETTINGS"],
      // => Configurações personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.label`,
          // => Captura automática
          property: "sdkOptions.capture.captureSettings.mode",
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.manual`,
              // => Manual
              value: "manual",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.automatic`,
              // => Automática
              value: "automatic",
            },
          ],
          defaultValue: "manual",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.label`,
          // => Tempo de captura automática
          property: "sdkOptions.capture.captureSettings.automaticCaptureTimeoutInSeconds",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.tooltip`,
          // => Tempo de espera, em segundos, para habilitar a captura manual
        },
        // Removendo o comentário do trecho abaixo a opção de áudios ficará disponível para todos
        // {
        //   type: onboardingInputTypes.SWITCH,
        //   label: `${I18N_BASE_PATH}.general.sdk.customSettings.playAudioDescription.label`,
        //   // => Mostrar Opção do sdk DD playAudioDescription
        //   property: "sdkOptions.playAudioDescription",
        //   tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.playAudioDescription.tooltip`,
        // },
      ],
    },
    // O trecho abaixo será removido em breve, pois a opção de áudios estará disponível para todos assim que as gravações forem melhoradas
    "STEP_CUSTOM-CONFIGURATIONS-DD-TEMP": {
      name: `${I18N_BASE_PATH}.general.sdk.customSettings.name`,
      rules: RULES["SHOULD_RENDER_DOCUMENT_AUTOMATIC_CAPTURE_SETTINGS"],
      // => Configurações personalizadas
      panel: true,
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.label`,
          // => Captura automática
          property: "sdkOptions.capture.captureSettings.mode",
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.manual`,
              // => Manual
              value: "manual",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.automatic`,
              // => Automática
              value: "automatic",
            },
          ],
          defaultValue: "manual",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.label`,
          // => Tempo de captura automática
          property: "sdkOptions.capture.captureSettings.automaticCaptureTimeoutInSeconds",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.tooltip`,
          // => Tempo de espera, em segundos, para habilitar a captura manual
        },
        {
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.playAudioDescription.label`,
          // => Mostrar Opção do sdk DD playAudioDescription
          property: "sdkOptions.playAudioDescription",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.playAudioDescription.tooltip`,
        },
      ],
    },
    // O trecho acima será removido em breve
    // DD_PREVIEW, PFL_PREVIEW
    STEP_PREVIEW_HEADING: {
      name: `${I18N_BASE_PATH}.general.title`,
      // => Título
      customizables: [
        {
          type: onboardingInputTypes.TEXT,
          property: "sdkOptions.initialize.customLabels.title",
        },
      ],
    },
    "STEP_PREVIEW-DD": {
      name: `${I18N_BASE_PATH}.STEP_PREVIEW-DD.name`,
      // => Preview
      customizables: [
        {
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.STEP_PREVIEW-DD.customizables.preview.label`,
          // => Mostrar preview
          property: "preview",
        },
        {
          rules: RULES["SHOULD_RENDER_PREVIEW_PDF_SWITCH"],
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.STEP_PREVIEW-DD.customizables.previewPDF.label`,
          // => Mostrar preview PDF
          property: "previewPDF",
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
          property: "previewHeading",
          defaultProperty: "previewStep.heading",
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.general.subtitle`,
          // => Subtítulo
          property: "previewSubheading",
          defaultProperty: "previewStep.subheading",
          rows: 3,
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.STEP_PREVIEW-DD.customizables.previewButton.label`,
          // => Visualizar documento
          property: "previewButton",
          defaultProperty: "previewStep.button",
          rows: 4,
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.STEP_PREVIEW-DD.customizables.previewRetryText.label`,
          // => Texto tirar novamente
          property: "previewRetryText",
          defaultProperty: "previewStep.retryText",
          rows: 5,
        },
      ],
    },
    STEP_QSA_NUMBER: {
      name: `${I18N_BASE_PATH}.STEP_QSA_NUMBER.name`,
      // => Número de sócios
      customizables: [
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "numberHeading",
          defaultProperty: "numberStep.heading",
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "numberSubheading",
          defaultProperty: "numberStep.subheading",
        },
      ],
    },
    STEP_QSA_CONTACT_INFO: {
      name: `${I18N_BASE_PATH}.STEP_QSA_CONTACT_INFO.name`,
      // => Informação de contato dos sócios
      customizables: [
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "contactInfoHeading",
          defaultProperty: "contactInfoStep.heading",
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "contactInfoSubheading",
          defaultProperty: "contactInfoStep.subheading",
        },
        {
          type: onboardingInputTypes.IMAGE,
          defaultProperty: "contactInfoStep.illustration",
          property: "contactInfoIllustration",
        },
      ],
    },
    STEP_QSA_CONTACT: {
      name: `${I18N_BASE_PATH}.STEP_QSA_CONTACT.name`,
      // => Contato dos sócios
      customizables: [
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "contactHeading",
          defaultProperty: "contactStep.heading",
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "contactSubheading",
          defaultProperty: "contactStep.subheading",
        },
      ],
    },
    "STEP_USE-TERMS": {
      name: `${I18N_BASE_PATH}.STEP_USE-TERMS.name`,
      // => Termos de aceite
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "terms",
          defaultValue: [],
          objectName: `${I18N_BASE_PATH}.STEP_USE-TERMS.customizables.terms.objectName`,
          // => termo
          fields: [
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.STEP_USE-TERMS.customizables.terms.fields.name.label`,
              // => Nome
              property: "name",
            },
            {
              type: onboardingInputTypes.TEXTAREA_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
              tooltip: `${I18N_BASE_PATH}.STEP_USE-TERMS.customizables.terms.fields.label.tooltip`,
              // => É importante demarcar onde o usuário clicará para exibir o termo. Para isso, demarque parte do texto com "[descrição](link)" para um link externo ou "[descricao]<texto-do-modal>" para abrir uma modal.
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.required`,
              // => Obrigatório
              property: "required",
              defaultValue: true,
            },
          ],
        },
      ],
    },
    STEP_USING_TERMS: {
      name: `${I18N_BASE_PATH}.STEP_USING-TERMS.name`,
      // => Termos de aceite
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "terms",
          defaultValue: [
            {
              label: "src.contexts.onboardingOptions.defaultOnboardingOptions.general.terms.label",
              name: "src.contexts.onboardingOptions.defaultOnboardingOptions.general.terms.name",
              required: true,
            },
          ],
          objectName: `${I18N_BASE_PATH}.STEP_USING-TERMS.customizables.terms.objectName`,
          // => termo
          fields: [
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.STEP_USING-TERMS.customizables.terms.fields.name.label`,
              // => Nome
              property: "name",
            },
            {
              type: onboardingInputTypes.TEXTAREA_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
              tooltip: `${I18N_BASE_PATH}.STEP_USING-TERMS.customizables.terms.fields.label.tooltip`,
              // => É importante demarcar onde o usuário clicará para exibir o termo. Para isso, demarque parte do texto com "[descrição](link)" para um link externo ou "[descricao]<texto-do-modal>" para abrir uma modal.
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.required`,
              // => Obrigatório
              property: "required",
              defaultValue: true,
            },
          ],
        },
      ],
    },
    STEP_TIPS: {
      name: `${I18N_BASE_PATH}.STEP_TIPS.name`,
      // => Instruções
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "tips",
          objectName: `${I18N_BASE_PATH}.STEP_TIPS.customizables.tips.objectName`,
          // => instrução
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.text`,
              // => Texto
              property: "text",
            },
          ],
        },
      ],
    },
    STEP_FIELDS: {
      name: `${I18N_BASE_PATH}.general.fields`,
      // => Campos
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS.customizables.fields.objectName`,
          // => campo
          limitOptions: true,
          optionsValueKey: "field",
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.STEP_FIELDS.customizables.fields.fields.field.label`,
              // => Campo
              valueKey: "field",
              labelKey: "description",
              property: "field",
            },
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
          ],
        },
      ],
    },
    STEP_FIELDS_ADDRESS: {
      name: `${I18N_BASE_PATH}.general.fields`,
      // => Campos
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS.customizables.fields.objectName`,
          // => campo
          limitOptions: true,
          optionsValueKey: "field",
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.STEP_FIELDS.customizables.fields.fields.field.label`,
              // => Campo
              valueKey: "field",
              labelKey: "description",
              property: "field",
            },
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.required`,
              // => Obrigatório
              property: "required",
            },
          ],
        },
      ],
    },
    STEP_FIELDS_BUSINESS_ADDRESS: {
      name: `${I18N_BASE_PATH}.general.fields`,
      // => Campos
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS.customizables.fields.objectName`,
          // => campo
          limitOptions: true,
          optionsValueKey: "field",
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.STEP_FIELDS.customizables.fields.fields.field.label`,
              // => Campo
              valueKey: "field",
              labelKey: "description",
              property: "field",
            },
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.required`,
              // => Obrigatório
              property: "required",
            },
          ],
        },
      ],
    },
    "STEP_DOCUMENT-SIDES-DESCRIPTION": {
      name: `${I18N_BASE_PATH}.STEP_DOCUMENT-SIDES-DESCRIPTION.customizables.checkDescription.title`,
      customizables: [
        {
          label: `${I18N_BASE_PATH}.STEP_DOCUMENT-SIDES-DESCRIPTION.customizables.checkDescription.label`,
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "checkDescription",
          defaultValue: `${I18N_BASE_PATH}.STEP_DOCUMENT-SIDES-DESCRIPTION.customizables.checkDescription.defaultValue`,
        },
      ],
    },
    "STEP_DOCUMENT-SIDES-DESCRIPTION": {
      name: `${I18N_BASE_PATH}.general.checkbox`,
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          limitOptions: true,
          canRemoveAll: false,
          property: "documentSides",
          defaultValue: [
            {
              id: "doubleSide",
              label: `${I18N_BASE_PATH}.general.yes`,
              value: true,
            },
            {
              id: "oneSide",
              label: `${I18N_BASE_PATH}.general.no`,
              value: false,
            },
          ],
          objectName: `${I18N_BASE_PATH}.general.checkbox`,
          fields: [
            {
              type: onboardingInputTypes.TEXTAREA_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.label`,
              property: "label",
            },
          ],
        },
      ],
    },
    STEP_FIELDS_DOCUMENT_ISSUED_COUNTRY: {
      name: `${I18N_BASE_PATH}.general.fields`,
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR_DEFAULT_FIELDS,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS_DISPATCH_ADDRESS.customizables.fields.objectName`,
          // => campo
          defaultFields: [
            {
              icon: "worldIcon",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.country.label`,
              // => País
              field: "countries",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.country.placeholder`,
              // => País de emissão do seu documento
              canBeSentAsDefault: true,
            },
          ],
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
          ],
        },
      ],
    },
    STEP_FIELDS_COMPANY_LOCATION: {
      name: `${I18N_BASE_PATH}.general.fields`,
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR_DEFAULT_FIELDS,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS_COMPANY_LOCATION.customizables.fields.objectName`,
          // => campo
          defaultFields: [
            {
              icon: "worldIcon",
              label: `${I18N_BASE_PATH}.general.companyLocation.country.label`,
              // => País
              field: "countries",
              placeholder: `${I18N_BASE_PATH}.general.companyLocation.country.placeholder`,
              // => País da empresa
              canBeSentAsDefault: true,
            },
            {
              icon: "worldIcon",
              label: `${I18N_BASE_PATH}.general.companyLocation.state.label`,
              // => Estado
              field: "states",
              placeholder: `${I18N_BASE_PATH}.general.companyLocation.state.placeholder`,
              // => Estado da empresa
              canBeSentAsDefault: true,
            },
          ],
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
          ],
        },
      ],
    },
    STEP_FIELDS_COMPANY_SEARCH: {
      name: `${I18N_BASE_PATH}.general.fields`,
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR_DEFAULT_FIELDS,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS_COMPANY_SEARCH.customizables.fields.objectName`,
          // => campo
          defaultFields: [
            {
              icon: "company",
              label: `${I18N_BASE_PATH}.general.companySearch.companyName.label`,
              // => País
              field: "companyName",
              placeholder: `${I18N_BASE_PATH}.general.companySearch.companyName.placeholder`,
              // => País da empresa
              canBeSentAsDefault: true,
            },
            {
              icon: "company",
              label: `${I18N_BASE_PATH}.general.companySearch.companyNumber.label`,
              // => Estado
              field: "companyNumber",
              placeholder: `${I18N_BASE_PATH}.general.companySearch.companyNumber.placeholder`,
              // => Estado da empresa
              canBeSentAsDefault: true,
            },
          ],
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
          ],
        },
      ],
    },
    STEP_FIELDS_DISPATCH_ADDRESS: {
      name: `${I18N_BASE_PATH}.general.fields`,
      // => Campos
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR_DEFAULT_FIELDS,
          property: "fields",
          objectName: `${I18N_BASE_PATH}.STEP_FIELDS_DISPATCH_ADDRESS.customizables.fields.objectName`,
          // => campo
          defaultFields: [
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.zipCode.label`,
              // => CEP
              field: "zipCode",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.zipCode.placeholder`,
              // => Digite seu CEP
              canBeSentAsDefault: true,
            },
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.state.label`,
              // => Estado
              field: "state",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.state.placeholder`,
              // => Selecione seu estado
              canBeSentAsDefault: true,
            },
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.city.label`,
              // => Cidade
              field: "city",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.city.placeholder`,
              // => Selecione sua cidade
              canBeSentAsDefault: true,
            },
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.street.label`,
              // => Rua
              field: "street",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.street.placeholder`,
              // => Digite a rua
              canBeSentAsDefault: true,
            },
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.neighborhood.label`,
              // => Bairro
              field: "neighborhood",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.neighborhood.placeholder`,
              // => Digite o bairro
              canBeSentAsDefault: true,
            },
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.number.label`,
              // => Número
              field: "number",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.number.placeholder`,
              // => Digite o número
              canBeSentAsDefault: true,
            },
            {
              icon: "home",
              label: `${I18N_BASE_PATH}.general.formAddress.customizables.complement.label`,
              // => Complemento
              field: "complement",
              placeholder: `${I18N_BASE_PATH}.general.formAddress.customizables.complement.placeholder`,
              // => Digite o complemento (opcional)
              canBeSentAsDefault: true,
            },
          ],
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
          ],
        },
      ],
    },
    "STEP_INCOME-INPUT": {
      name: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.name`,
      // => Faturamento
      customizables: [
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.customizables.default.label`,
          // => Valor padrão
          defaultValue: 0,
          property: "default",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.customizables.max.label`,
          // => Valor máximo
          property: "max",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.customizables.min.label`,
          // => Valor mínimo
          property: "min",
        },
        {
          label: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.customizables.frequency.label`,
          // => Frequência
          type: onboardingInputTypes.SELECT,
          property: "frequency",
          options: [
            {
              label: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.customizables.frequency.options.monthly`,
              // => Mensal
              value: "monthly",
            },
            {
              label: `${I18N_BASE_PATH}.STEP_INCOME-INPUT.customizables.frequency.options.annually`,
              // => Anual
              value: "annually",
            },
          ],
          defaultValue: "monthly",
          labelKey: "label",
          valueKey: "value",
        },
      ],
    },
    "STEP_CUSTOM-FIELDS": {
      name: `${I18N_BASE_PATH}.STEP_CUSTOM-FIELDS.name`,
      // => Formulário customizado
      customizables: [
        {
          type: onboardingInputTypes.TEXT,
          label: `${I18N_BASE_PATH}.STEP_CUSTOM-FIELDS.customizables.formName.label`,
          // => Id do formulário
          property: "formName",
        },
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "fields",
          optionsValueKey: "type",
          objectName: `${I18N_BASE_PATH}.STEP_CUSTOM-FIELDS.customizables.fields.objectName`,
          // => campo
          options: [
            {
              type: "title",
              fields: [
                {
                  type: onboardingInputTypes.TEXT_WITH_VARIABLE,
                  previewMode: previewModes.TEXT,
                  label: `${I18N_BASE_PATH}.general.title`,
                  // => Texto
                  property: "text",
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.bold`,
                  // => Negrito
                  property: "bold",
                },
              ],
            },
            {
              type: "checkbox",
              fields: [
                {
                  type: onboardingInputTypes.TEXT,
                  label: `${I18N_BASE_PATH}.general.id`,
                  // => Id
                  tooltip: `${I18N_BASE_PATH}.general.neitherSpecialCharactersNorSpaces`,
                  // => Sem caracteres especiais nem espaço
                  property: "id",
                  valueKey: "id",
                  previewMode: previewModes.TEXT,
                },
                {
                  type: onboardingInputTypes.MULTISELECT_TAGS,
                  label: `${I18N_BASE_PATH}.general.options`,
                  // => Opções
                  valueKey: "options",
                  property: "options",
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.required`,
                  // => Obrigatório
                  property: "required",
                },
              ],
            },
            {
              type: "radio",
              fields: [
                {
                  type: onboardingInputTypes.TEXT,
                  label: `${I18N_BASE_PATH}.general.id`,
                  // => Id
                  tooltip: `${I18N_BASE_PATH}.general.neitherSpecialCharactersNorSpaces`,
                  // => Sem caracteres especiais nem espaço
                  valueKey: "id",
                  property: "id",
                  previewMode: previewModes.TEXT,
                },
                {
                  type: onboardingInputTypes.MULTISELECT_TAGS,
                  label: `${I18N_BASE_PATH}.general.options`,
                  // => Opções
                  valueKey: "options",
                  property: "options",
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.required`,
                  // => Obrigatório
                  property: "required",
                },
              ],
            },
            {
              type: "switch",
              fields: [
                {
                  type: onboardingInputTypes.TEXT,
                  label: `${I18N_BASE_PATH}.general.id`,
                  // => Id
                  tooltip: `${I18N_BASE_PATH}.general.neitherSpecialCharactersNorSpaces`,
                  // => Sem caracteres especiais nem espaço
                  valueKey: "id",
                  property: "id",
                  previewMode: previewModes.TEXT,
                },
                {
                  type: onboardingInputTypes.TEXT_WITH_VARIABLE,
                  label: `${I18N_BASE_PATH}.general.label`,
                  // => Label
                  property: "label",
                  previewMode: previewModes.LABEL,
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.bold`,
                  // => Negrito
                  property: "bold",
                  tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-FIELDS.customizables.fields.options.switch.fields.bold.tooltip`,
                  // => Ative essa opção para dar destaque à label
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.required`,
                  // => Obrigatório
                  property: "required",
                },
              ],
            },
            {
              type: "select",
              fields: [
                {
                  type: onboardingInputTypes.TEXT,
                  label: `${I18N_BASE_PATH}.general.id`,
                  // => Id
                  tooltip: `${I18N_BASE_PATH}.general.neitherSpecialCharactersNorSpaces`,
                  // => Sem caracteres especiais nem espaço
                  property: "id",
                },
                {
                  type: onboardingInputTypes.SELECT_ICONS,
                  label: `${I18N_BASE_PATH}.general.icon`,
                  // => Ícone
                  property: "icon",
                  previewMode: previewModes.ICON,
                },
                {
                  type: onboardingInputTypes.TEXT_WITH_VARIABLE,
                  label: `${I18N_BASE_PATH}.general.label`,
                  // => Label
                  property: "label",
                  previewMode: previewModes.LABEL,
                },
                {
                  type: onboardingInputTypes.TEXT_WITH_VARIABLE,
                  label: `${I18N_BASE_PATH}.general.placeholder.label`,
                  // => Placeholder
                  tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
                  // => Texto quando o campo está vazio
                  property: "placeholder",
                  previewMode: previewModes.TEXT,
                },
                {
                  type: onboardingInputTypes.MULTISELECT_TAGS,
                  label: `${I18N_BASE_PATH}.general.options`,
                  // => Opções
                  property: "options",
                  allowImportCSV: true,
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.multiple`,
                  // => Múltiplo
                  property: "multiple",
                  tooltip: `${I18N_BASE_PATH}.STEP_CUSTOM-FIELDS.customizables.fields.options.select.fields.multiple.tooltip`,
                  // => Será possível escolher uma ou mais opções
                },
                {
                  type: onboardingInputTypes.SWITCH,
                  label: `${I18N_BASE_PATH}.general.required`,
                  // => Obrigatório
                  property: "required",
                },
              ],
            },
            {
              type: "input",
              fields: [
                {
                  type: onboardingInputTypes.TEXT,
                  label: `${I18N_BASE_PATH}.general.id`,
                  // => Id
                  tooltip: `${I18N_BASE_PATH}.general.neitherSpecialCharactersNorSpaces`,
                  // => Sem caracteres especiais nem espaço
                  property: "id",
                },
                {
                  type: onboardingInputTypes.SELECT_INPUT_TYPES,
                  label: `${I18N_BASE_PATH}.general.inputType.label`,
                  // => Tipo do input
                  property: "inputType",
                  tooltip: `${I18N_BASE_PATH}.general.inputType.tooltip`,
                  // => Selecione a máscara do campo
                },
                {
                  type: onboardingInputTypes.TEXT_WITH_VARIABLE,
                  previewMode: previewModes.LABEL,
                  label: `${I18N_BASE_PATH}.general.label`,
                  // => Label
                  property: "label",
                },
                {
                  type: onboardingInputTypes.SELECT_ICONS,
                  previewMode: previewModes.ICON,
                  label: `${I18N_BASE_PATH}.general.icon`,
                  // => Ícone
                  property: "icon",
                },
                {
                  type: onboardingInputTypes.TEXT_WITH_VARIABLE,
                  label: `${I18N_BASE_PATH}.general.placeholder.label`,
                  // => Placeholder
                  tooltip: `${I18N_BASE_PATH}.general.placeholder.tooltip`,
                  // => Texto quando o campo está vazio
                  property: "placeholder",
                  previewMode: previewModes.TEXT,
                },
                { type: onboardingInputTypes.MAX_LENGTH, property: "maxLength" },
                { type: onboardingInputTypes.SWITCH_OPTION, property: "required" },
              ],
            },
          ],
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.general.component`,
              // => Componente
              valueKey: "type",
              labelKey: "type",
              property: "type",
            },
          ],
        },
      ],
    },
    "STEP_FIELD-CNPJ": {
      name: `${I18N_BASE_PATH}.STEP_FIELD-CNPJ.name`,
      // => Campo de CNPJ
      customizables: [
        {
          label: `${I18N_BASE_PATH}.general.icon`,
          // => Ícone
          type: onboardingInputTypes.SELECT_ICONS,
          property: "fieldCnpj.icon",
        },
        {
          label: `${I18N_BASE_PATH}.general.label`,
          // => Label
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "fieldCnpj.label",
        },
        {
          label: `${I18N_BASE_PATH}.general.placeholder.label`,
          // => Placeholder
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "fieldCnpj.placeholder",
        },
      ],
    },
    STEP_DOCUMENTS: {
      name: `${I18N_BASE_PATH}.STEP_DOCUMENTS.name`,
      // => Documentos
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "fields",
          limitOptions: true,
          optionsValueKey: "type",
          objectName: `${I18N_BASE_PATH}.STEP_DOCUMENTS.customizables.fields.objectName`,
          // => documento
          options: Object.values(
            onboardingPropsParserTranslator(defaultOnboardingValues?.defaultUploadDocumentsTypesOptions, language),
          ),
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.STEP_DOCUMENTS.customizables.fields.fields.type.label`,
              // => Documento
              valueKey: "type",
              labelKey: "title",
              property: "type",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "title",
            },
            {
              type: onboardingInputTypes.TEXT,
              inputType: "number",
              label: `${I18N_BASE_PATH}.STEP_DOCUMENTS.customizables.fields.fields.maxSize.label`,
              // => Tamanho máx. (MB)
              property: "maxSize",
              tooltip: `${I18N_BASE_PATH}.STEP_DOCUMENTS.customizables.fields.fields.maxSize.tooltip`,
              // => Caso não informado, o tamanho máximo será de 10MB, o padrão
            },
            {
              type: onboardingInputTypes.MULTISELECT_EXTENSIONS,
              label: `${I18N_BASE_PATH}.STEP_DOCUMENTS.customizables.fields.fields.extensions.label`,
              // => Extensões
              property: "extensions",
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.multiple`,
              // => Múltiplo
              property: "multiple",
              tooltip: `${I18N_BASE_PATH}.STEP_DOCUMENTS.customizables.fields.fields.multiple.tooltip`,
              // => O campo aceitará o upload de múltiplos arquivos
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.required`,
              // => Obrigatório
              property: "required",
            },
          ],
        },
      ],
    },
    "STEP_FLOW-OPTIONS": {
      name: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.name`,
      // => Opções de Fluxo"
      help: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.help`,
      // => Fluxos de etapas que o usuário poderá selecionar, seguindo por caminhos diferentes durante o Onboarding
      customizables: [
        {
          label: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.inputType.label`,
          // => Tipo de input
          type: onboardingInputTypes.SELECT,
          property: "inputType",
          options: [
            {
              label: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.inputType.options.radio`,
              // => Radio
              value: "radio",
            },
            {
              label: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.inputType.options.select`,
              // => Select
              value: "select",
            },
          ],
          labelKey: "label",
          valueKey: "value",
          defaultValue: "radio",
        },
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          label: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.options.label`,
          // => Fluxos
          property: "options",
          objectName: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.options.objectName`,
          // => fluxo
          fields: [
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.options.fields.flow.label`,
              // => Fluxo
              property: "flow",
              tooltip: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.options.fields.flow.tooltip`,
              // => Nome do fluxo
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.text`,
              // => Texto
              property: "text",
            },
          ],
        },
      ],
    },
    "STEP_SMART-OPTIONS": {
      customizables: [
        {
          type: onboardingInputTypes.SMART_LIST,
          label: `${I18N_BASE_PATH}.STEP_SMART-OPTIONS.customizables.options.label`,
          // => Fluxos
          property: "options",
          objectName: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.options.objectName`,
          // => fluxo
          fields: [
            {
              type: onboardingInputTypes.NUMBER,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.STEP_FLOW-OPTIONS.customizables.options.fields.flow.label`,
              // => Fluxo
              property: "flow",
            },
            {
              type: onboardingInputTypes.NUMBER,
              label: `${I18N_BASE_PATH}.general.text`,
              // => Texto
              property: "text",
            },
          ],
        },
      ],
    },
    STEP_SMART_FLOW_RULE: {
      name: `${I18N_BASE_PATH}.STEP_SMART_FLOW_RULE.name`,
      // => Este recurso não gera uma nova tela no seu fluxo, ele apenas cria uma regra."
      customizables: [
        {
          label: `${I18N_BASE_PATH}.STEP_SMART_FLOW_RULE.customizables.inputType.label`,
          // => Selecione uma regra
          type: onboardingInputTypes.SELECT,
          property: "inputType",
          options: [
            {
              label: `${I18N_BASE_PATH}.STEP_SMART_FLOW_RULE.customizables.inputType.options.select`,
              // => Captura de documentos
              value: "select",
            },
          ],
          labelKey: "label",
          valueKey: "value",
          defaultValue: `${I18N_BASE_PATH}.STEP_SMART_FLOW_RULE.customizables.inputType.options.select`,
          disabled: true,
        },
      ],
    },
    "STEP_REDIRECT-BUTTON": {
      name: `${I18N_BASE_PATH}.STEP_REDIRECT-BUTTON.name`,
      // => Botão de redirecionamento
      customizables: [
        {
          label: `${I18N_BASE_PATH}.general.text`,
          // => Texto
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "redirectText",
          defaultValue: `${I18N_BASE_PATH}.STEP_REDIRECT-BUTTON.customizables.redirectText.defaultValue`,
          // => Continuar cadastro
        },
        {
          label: `${I18N_BASE_PATH}.STEP_REDIRECT-BUTTON.customizables.redirectUrl.label`,
          // => Link
          tooltip: `${I18N_BASE_PATH}.STEP_REDIRECT-BUTTON.customizables.redirectUrl.tooltip`,
          // => URL para qual o usuário será redirecionado
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "redirectUrl",
        },
      ],
    },
    "STEP_POST-EVENT-MESSAGE": {
      name: `${I18N_BASE_PATH}.STEP_POST-EVENT-MESSAGE.name`,
      // => Ativar espera no evento de finalização
      customizables: [
        {
          label: `${I18N_BASE_PATH}.STEP_POST-EVENT-MESSAGE.customizables.postEventMessage.label`,
          // => Ativar espera
          tooltip: `${I18N_BASE_PATH}.STEP_POST-EVENT-MESSAGE.customizables.postEventMessage.tooltip`,
          type: onboardingInputTypes.SWITCH,
          property: "postEventMessage",
        },
      ],
    },
    STEP_ERRORS: {
      name: `${I18N_BASE_PATH}.STEP_ERRORS.name`,
      rules: RULES["SHOULD_NOT_RENDER_ERRORS_IN_BUSINESS_ADDRESS_WHEN_KYB_THEME"],
      // => Erros
      customizables: [
        {
          type: onboardingInputTypes.ERRORS_LIST,
          fields: [
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.message`,
              // => Mensagem
              property: "text",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "heading",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.subtitle`,
              // => Subtítulo
              property: "subheading",
            },
            {
              type: onboardingInputTypes.IMAGE,
              label: `${I18N_BASE_PATH}.general.illustration`,
              // => Ilustração
              property: "illustration",
            },
          ],
        },
      ],
    },
    "STEP_DOCUMENT-TYPES": {
      name: `${I18N_BASE_PATH}.STEP_DOCUMENT-TYPES.name`,
      // => Tipos de documento
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "documentTypes",
          objectName: `${I18N_BASE_PATH}.STEP_DOCUMENT-TYPES.customizables.documentTypes.objectName`,
          // => documento
          isRootProperty: true,
          limitOptions: true,
          optionsValueKey: "type",
          options: Object.values(
            onboardingPropsParserTranslator(defaultOnboardingValues?.defaultDocumentTypesOptions, language),
          ),
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.general.type`,
              // => Tipo
              property: "type",
              valueKey: "type",
              labelKey: "type",
            },
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "title",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.description`,
              // => Descrição
              property: "description",
            },
          ],
        },
      ],
    },
    STEP_QSA: {
      name: `${I18N_BASE_PATH}.STEP_QSA.name`,
      // => QSA
      customizables: [
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.STEP_QSA.customizables.behavior.label`,
          tooltip: `${I18N_BASE_PATH}.STEP_QSA.customizables.behavior.tooltip`,
          property: "behavior",
          labelKey: "label",
          valueKey: "value",
          options: [
            {
              label: `${I18N_BASE_PATH}.STEP_QSA.customizables.behavior.options.partner`,
              value: "partner",
            },
            {
              label: `${I18N_BASE_PATH}.STEP_QSA.customizables.behavior.options.employee`,
              value: "employee",
            },
          ],
        },
        {
          tooltip: `${I18N_BASE_PATH}.STEP_QSA.customizables.manual.tooltip`,
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.STEP_QSA.customizables.manual.label`,
          // => QSA Manual
          property: "manual",
        },
        {
          tooltip: `${I18N_BASE_PATH}.STEP_QSA.customizables.disableManualInsertOption.tooltip`,
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.STEP_QSA.customizables.disableManualInsertOption.label`,
          // => Desabilitar "Nenhum (Inserir manualmente)"
          property: "disableManualInsertOption",
        },
      ],
    },
    STEP_ATTORNEY: {
      name: `${I18N_BASE_PATH}.STEP_QSA.name`,
      // => QSA
      customizables: [
        {
          tooltip: `${I18N_BASE_PATH}.STEP_QSA.customizables.disableManualInsertOption.tooltip`,
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.STEP_QSA.customizables.disableManualInsertOption.label`,
          // => Desabilitar "Nenhum (Inserir manualmente)"
          property: "disableManualInsertOption",
        },
      ],
    },
    STEP_PREVIEW: {
      name: `${I18N_BASE_PATH}.STEP_PREVIEW.name`,
      // => Preview
      customizables: [
        {
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.STEP_PREVIEW.customizables.preview.label`,
          // => Mostrar preview
          property: "preview",
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
          property: "previewHeading",
          defaultProperty: "previewStep.heading",
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.general.subtitle`,
          // => Subtítulo
          property: "previewSubheading",
          defaultProperty: "previewStep.subheading",
          rows: 3,
        },
        {
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          label: `${I18N_BASE_PATH}.STEP_PREVIEW.customizables.previewRetryText.label`,
          // => Texto tirar novamente
          property: "previewRetryText",
          defaultProperty: "previewStep.retryText",
          rows: 4,
        },
      ],
    },
    "STEP_SEND-DOCUMENT-TYPES": {
      name: `${I18N_BASE_PATH}.STEP_SEND-DOCUMENT-TYPES.name`,
      // => Enviar tipo de documento
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "sendDocumentTypes",
          objectName: `${I18N_BASE_PATH}.STEP_SEND-DOCUMENT-TYPES.customizables.sendDocumentTypes.objectName`,
          // => forma de envio
          limitOptions: true,
          optionsValueKey: "type",
          defaultValue: Object.values(
            onboardingPropsParserTranslator(defaultOnboardingValues?.defaultSendDocumentTypesOptions, language),
          ),
          canNotRemoveThisField: {
            key: "id",
            value: "upload",
          },
          options: Object.values(
            onboardingPropsParserTranslator(defaultOnboardingValues?.defaultSendDocumentTypesOptions, language),
          ),
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "title",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.description`,
              // => Descrição
              property: "description",
            },
          ],
        },
      ],
    },
    SEND_DOCUMENT_TYPE_MODAL: {
      name: `${I18N_BASE_PATH}.SEND_DOCUMENT_TYPE_MODAL.name`,
      // => Formato do arquivo
      customizables: [
        {
          property: "modalDocumentHeading",
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          rows: 2,
          defaultValue: `${I18N_BASE_PATH}.SEND_DOCUMENT_TYPE_MODAL.customizables.modalDocumentHeading.defaultValue`,
          // => Anexar Documento
        },
        {
          property: "modalDocumentSubheading",
          label: `${I18N_BASE_PATH}.general.subtitle`,
          // => Subtítulo
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          rows: 3,
          defaultValue: `${I18N_BASE_PATH}.SEND_DOCUMENT_TYPE_MODAL.customizables.modalDocumentSubheading.defaultValue`,
          // => Escolha o formato do arquivo que deseja anexar
        },
        {
          label: `${I18N_BASE_PATH}.general.button`,
          // => Botão
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
          property: "modalDocumentButton",
          defaultValue: `${I18N_BASE_PATH}.SEND_DOCUMENT_TYPE_MODAL.customizables.modalDocumentButton.defaultValue`,
          // => Anexar documento
        },
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "sendDocumentTypeModal",
          objectName: `${I18N_BASE_PATH}.SEND_DOCUMENT_TYPE_MODAL.customizables.sendDocumentTypeModal.objectName`,
          // => tipo de documento
          defaultValue: Object.values(
            onboardingPropsParserTranslator(defaultOnboardingValues?.defaultSendDocumentTypesModal, language),
          ),
          canNotRemoveThisField: {
            key: "id",
            value: "uploadPdf",
          },
          limitOptions: true,
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "title",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.description`,
              // => Descrição
              property: "description",
            },
          ],
        },
      ],
    },
  };
  Object.freeze(customizableElements);

  return customizableElements;
};

export default renderCustomizableElements;
