import { Icon } from "@combateafraude/react";
import { DropDown } from "@components/DropDown";
import ErrorsList from "@components/ErrorsList";
import {
  AutoCompleteInput,
  ColorInput,
  RequiredMaxLengthInput,
  RequiredSwitchOption,
  SelectMultipleInput,
  UploadInput,
} from "@components/Inputs";
import ObjectListEditor from "@components/ObjectListEditor";
import { ObjectListEditorByDefaultFields } from "@components/ObjectListEditor";
import RulesList from "@components/RulesList";
import SmartOptionsList from "@components/SmartOptionsList";
import { updateTimeout, useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import { useOnboardingVariablesContext } from "@contexts/OnboardingVariables";
import { useSidebarEditorContext } from "@contexts/SidebarEditor";
import useTimeout from "@hooks/useTimeout";
import Onboarding from "@package/steps";
import { icons } from "@utils/icons";
import { inputTypes } from "@utils/inputTypes";
import { parseStep } from "@utils/onboarding";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { Checkbox, Input, Row, Select, Switch } from "antd";
import classNames from "classnames";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { onboardingInputTypes } from ".";

const { steps } = Onboarding;

const { TextArea } = Input;

const I18N_BASE_PATH = "src.components.inputs.onboardingInput";

/** Dynamic input to edit the OnboardingOptions */
const OnboardingInput = ({
  type = onboardingInputTypes.TEXT,
  customizableName,
  customizablePath,
  suffix,
  prefix,
  // Property to define <AutoCompleteInput /> size
  autoCompleteInputSize,
  // Property to modify in onboardingOptions
  property,
  // Default property if there is no value in "property"
  defaultProperty,
  // If true, sets the value in the root and not in the step
  isRootProperty,
  // If true, sets uses "errorName" property to change the error object
  isErrorProperty,
  errorName,
  // Default property if there is no value in "property" or "defaultProperty"
  defaultValue,
  // If the property is party of a step
  stepIndex,
  // If the property is an object, key for value
  valueKey,
  // If the property is an object, key for label
  labelKey,
  required = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const { onboardingOptions, setOnboardingOptions, initialOnboardingOptions, onboardingLanguage } =
    useOnboardingOptionsContext();
  const { onboardingSteps, setOnboardingSteps, initialOnboardingSteps } = useOnboardingStepsContext();
  const { variables, preventUserTypingInvalidCharacters } = useOnboardingVariablesContext();

  /** Adds the errorName before the property, when needed */
  const [inputProperty, setInputProperty] = useState();
  useEffect(() => {
    if (property) {
      if (isErrorProperty && errorName) {
        setInputProperty(`errors.${errorName}.${property}`);
      } else if (customizablePath) {
        setInputProperty(`${customizablePath}.${property}`);
      } else {
        setInputProperty(property);
      }
    }
  }, [property, customizablePath, isErrorProperty, errorName]);

  const [inputValue, setInputValue] = useState(null);
  const [inputRealValue, setInputRealValue] = useState(null);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  useEffect(() => {
    if (inputProperty && (inputValue !== "" || !required)) {
      if (isRootProperty || isNaN(stepIndex)) {
        setInputRealValue(
          get(
            onboardingOptions,
            inputProperty,
            defaultProperty ? get(onboardingOptions, defaultProperty) : defaultValue,
          ),
        );
      }
    }
  }, [inputProperty, onboardingOptions, stepIndex]);

  useEffect(() => {
    if (inputProperty) {
      if (!isRootProperty && !isNaN(stepIndex)) {
        setInputRealValue(
          get(
            onboardingSteps[stepIndex],
            inputProperty,
            defaultProperty ? get(onboardingSteps[stepIndex], defaultProperty) : defaultValue,
          ),
        );
      }
    }
  }, [inputProperty, onboardingSteps, stepIndex]);

  // Sets defaultChecked value to Switch
  useEffect(() => {
    if (
      (type === onboardingInputTypes.SWITCH || type === onboardingInputTypes.SWITCH_OPTION) &&
      (defaultValue || typeof defaultValue === "boolean") &&
      inputProperty
    ) {
      onInputChange(defaultValue);
    }
    if (
      type === onboardingInputTypes.OBJECT_LIST_EDITOR &&
      (defaultValue || typeof defaultValue === "boolean") &&
      inputProperty &&
      hasChanged
    ) {
      onInputChange(defaultValue);
    }
  }, [defaultValue, inputProperty]);

  const { focusedCustomizableElement, setFocusedCustomizableElement, inputTypeSelected, setInputTypeSelected } =
    useSidebarEditorContext();
  useEffect(() => {
    if (inputIsFocused) setFocusedCustomizableElement(customizableName);
    else {
      setFocusedCustomizableElement((old) => (old === customizableName ? undefined : old));
      setInputValue(inputRealValue);
    }
  }, [inputRealValue, inputIsFocused]);

  const inputRef = useRef(null);
  // Focus the input when the user clicks in the customizableElement
  useEffect(() => {
    if (focusedCustomizableElement && focusedCustomizableElement === customizableName && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [focusedCustomizableElement, customizableName]);

  const [hasChanged, setHasChanged] = useState(false);
  const [stepData, setStepData] = useState();
  useEffect(() => {
    if (onboardingSteps && initialOnboardingSteps) {
      let step = onboardingSteps[stepIndex];
      let initialStep = initialOnboardingSteps?.find((_step) => step?.name === _step?.name);
      if (initialStep) setStepData(initialStep);
      else setStepData(onboardingPropsParserTranslator(steps)[parseStep(step?.name)[0]]);
    }
  }, [initialOnboardingSteps, onboardingSteps, stepIndex]);

  useEffect(() => {
    if (inputProperty) {
      if (isRootProperty || isNaN(stepIndex)) {
        setHasChanged(
          inputRealValue !== (defaultProperty ? get(initialOnboardingOptions, defaultProperty) : defaultValue) &&
            inputRealValue !== get(initialOnboardingOptions, inputProperty),
        );
      } else {
        setHasChanged(
          inputRealValue !== (defaultProperty ? get(stepData, defaultProperty) : defaultValue) &&
            inputRealValue !== get(stepData, inputProperty),
        );
      }
    }
  }, [inputProperty, inputRealValue]);

  // True if a value is currently updating in onboardingOptions
  const [isUpdatingValue, setIsUpdatingValue] = useState(false);
  const { callbackOnTimeout } = useTimeout();

  const onInputChange = (value) => {
    if (!inputProperty) return;

    if (typeof value === "string") value = value?.trimStart();

    setInputValue(value);

    let instant;
    switch (type) {
      case onboardingInputTypes.SELECT:
      case onboardingInputTypes.SELECT_MULTIPLE:
      case onboardingInputTypes.SWITCH:
      case onboardingInputTypes.SWITCH_OPTION:
      case onboardingInputTypes.IMAGE:
      case onboardingInputTypes.RULES_LIST:
        instant = true;
        break;
      default:
        instant = false;
    }

    if (!value && required) {
      let _onboardingOptions = cloneDeep(onboardingOptions);
      set(_onboardingOptions, inputProperty, get(initialOnboardingOptions, inputProperty));
      setOnboardingOptions(_onboardingOptions, instant, false);

      setInputRealValue(value);

      return;
    }

    if (type === onboardingInputTypes.NUMBER) value = +value;

    if (isRootProperty || isNaN(stepIndex)) {
      let _onboardingOptions = cloneDeep(onboardingOptions);
      set(_onboardingOptions, inputProperty, value);
      setOnboardingOptions(_onboardingOptions, instant);
    } else {
      let _onboardingSteps = cloneDeep(onboardingSteps);
      set(_onboardingSteps[stepIndex], inputProperty, value);
      setOnboardingSteps(_onboardingSteps, instant);
      /**
       * A condicional abaixo foi criada para desabilitar a opção "previewPDF" (switch component), sempre que o "preview"
       * for desabilitado, pois é um requisito do SDK.
       */
      if (inputProperty === "preview" && value === false) {
        set(_onboardingSteps[stepIndex], "previewPDF", false);
        setOnboardingSteps(_onboardingSteps, instant);
      }
    }
    // Set is updating based on onboardingOptions defined update timeout
    if (!instant && updateTimeout) {
      setIsUpdatingValue(true);
      callbackOnTimeout(() => setIsUpdatingValue(false), updateTimeout + 50);
    }
  };

  const resetInputValue = () => {
    if (type === onboardingInputTypes.COLOR || isNaN(stepIndex)) {
      onInputChange(get(initialOnboardingOptions, inputProperty));
    } else if (isRootProperty) {
      onInputChange(get(initialOnboardingOptions, inputProperty));
    } else {
      onInputChange(get(stepData, inputProperty));
    }
  };

  const resetButton = ({ isAutoCompleteInput, autoCompleteInputSize }) => (
    <span
      className={classNames("relative h-3/4 transition-all", hasChanged || isUpdatingValue ? "w-3.5" : "w-0", {
        "right-6": isAutoCompleteInput,
        "top-2": autoCompleteInputSize === "medium",
        "top-1": autoCompleteInputSize === "small",
      })}
      title={
        isUpdatingValue
          ? t(`${I18N_BASE_PATH}.components.resetButton.loadingField`, "Carregando campo")
          : t(`${I18N_BASE_PATH}.components.resetButton.resetField`, "Redefinir campo")
      }
      onClick={() => hasChanged && resetInputValue()}
    >
      <Icon
        className={classNames(
          "absolute text-gray-300 animate-spin duration-150",
          isUpdatingValue ? "delay-150 opacity-100 " : "opacity-0 pointer-events-none",
        )}
        icon="rotate-cw"
        size="sm"
      />
      <Icon
        className={classNames("absolute transition-all duration-150", {
          "text-gray-500 hover:text-caf-primary cursor-pointer": hasChanged,
          "opacity-0 pointer-events-none": !hasChanged || isUpdatingValue,
          "delay-150": !isUpdatingValue,
        })}
        icon="rotate-ccw"
        size="sm"
      />
    </span>
  );

  const inputSuffix = () => (
    <>
      {resetButton({ isAutoCompleteInput: false })}
      {suffix && <div className={classNames({ "pl-2": hasChanged || isUpdatingValue })}>{suffix}</div>}
    </>
  );

  const inputPrefix = () => <>{prefix && <div>{prefix}</div>}</>;

  const focusProps = {
    // ref: inputRef,
    onFocus: () => setInputIsFocused(true),
    onBlur: () => setInputIsFocused(false),
  };

  return (
    <>
      {type === onboardingInputTypes.COLOR && (
        <ColorInput
          hexColor={inputValue || inputRealValue}
          onInputChange={onInputChange}
          suffix={inputSuffix()}
          prefix={inputPrefix()}
          {...focusProps}
          {...rest}
        />
      )}
      {type === onboardingInputTypes.TEXT && (
        <Input
          value={
            onboardingPropsParserTranslator(inputValue, onboardingLanguage) ||
            onboardingPropsParserTranslator(inputRealValue, onboardingLanguage)
          }
          onChange={(e) => onInputChange(e.target.value)}
          suffix={inputSuffix()}
          prefix={inputPrefix()}
          // {...focusProps}
          onKeyPress={(e) => rest?.userCantTypeInvalidCharacters && preventUserTypingInvalidCharacters({ char: e })}
        />
      )}
      {(type === onboardingInputTypes.TEXT_WITH_VARIABLE || type === onboardingInputTypes.TEXTAREA_WITH_VARIABLE) && (
        <AutoCompleteInput
          trigger={"{{"}
          options={variables}
          componentType={type === onboardingInputTypes.TEXT_WITH_VARIABLE ? "input" : "textarea"}
          spacer="}}"
          value={
            onboardingPropsParserTranslator(inputValue, onboardingLanguage) ||
            onboardingPropsParserTranslator(inputRealValue, onboardingLanguage)
          }
          onChange={onInputChange}
          size={rest?.autoCompleteInputSize}
          resetButton={resetButton}
          autoComplete="off"
          extraPaddingOnIcon={isUpdatingValue || hasChanged}
          {...focusProps}
        />
      )}
      {type === onboardingInputTypes.NUMBER && (
        <Input
          {...rest}
          type="number"
          value={inputValue || inputRealValue}
          onChange={(e) => onInputChange(e.target.value)}
          suffix={inputSuffix()}
          prefix={inputPrefix()}
          {...focusProps}
        />
      )}
      {type === onboardingInputTypes.MAX_LENGTH && (
        <RequiredMaxLengthInput
          value={
            onboardingPropsParserTranslator(inputValue, onboardingLanguage) ||
            onboardingPropsParserTranslator(inputRealValue, onboardingLanguage)
          }
          inputTypeSelected={inputTypeSelected}
          onChange={(value) => onInputChange(value)}
          {...focusProps}
        />
      )}
      {type === onboardingInputTypes.TEXT_AREA && (
        <TextArea
          rows={rest?.rows || 5}
          value={
            onboardingPropsParserTranslator(inputValue, onboardingLanguage) ||
            onboardingPropsParserTranslator(inputRealValue, onboardingLanguage)
          }
          onChange={(e) => onInputChange(e.target.value)}
          {...focusProps}
        />
      )}
      {type === onboardingInputTypes.IMAGE && (
        <UploadInput
          value={inputRealValue}
          property={inputProperty}
          defaultProperty={defaultProperty}
          stepData={stepData}
          onInputChange={onInputChange}
          {...rest}
        />
      )}
      {type === onboardingInputTypes.SELECT && (
        <Select
          value={inputRealValue}
          onChange={onInputChange}
          loading={rest?.loading}
          disabled={rest?.disabled}
          placeholder={rest?.placeholder}
          dropdownRender={rest?.dropdownRender}
          {...focusProps}
        >
          {rest?.options?.map((option, i) => (
            <Select.Option key={`ONBOARDING_INPUT_OPTION_${i}`} value={valueKey ? option[valueKey] : option}>
              <span style={option?.style}>
                {labelKey ? onboardingPropsParserTranslator(String(option[labelKey]), onboardingLanguage) : option}
              </span>
            </Select.Option>
          ))}
        </Select>
      )}
      {type === onboardingInputTypes.SELECT_MULTIPLE && (
        <SelectMultipleInput {...{ inputValue: inputRealValue, onInputChange, valueKey, labelKey, ...rest }} />
      )}
      {type === onboardingInputTypes.MULTISELECT_TAGS && (
        <Select
          mode="tags"
          placeholder={onboardingPropsParserTranslator(rest?.label, onboardingLanguage)}
          value={inputRealValue}
          onChange={onInputChange}
          className="font-normal !text-xs"
          notFoundContent={
            <small>
              <Trans i18nKey={`${I18N_BASE_PATH}.components.select.notFoundContent`}>
                Digite uma opção e aperte <kbd>TAB</kbd> ou <kbd>ENTER</kbd>
              </Trans>
            </small>
          }
          maxTagCount={10}
          maxTagTextLength={15}
          showSearch
        />
      )}
      {type === onboardingInputTypes.MULTISELECT_EXTENSIONS && (
        <Select
          mode="multiple"
          placeholder={onboardingPropsParserTranslator(rest?.label, onboardingLanguage)}
          value={inputRealValue}
          onChange={onInputChange}
          className="font-normal !text-xs"
        >
          {["pdf", "png", "jpeg", "jpg"].map((extension) => (
            <Select.Option key={`MULTISELECT_EXTENSIONS_${valueKey}_${extension}`} value={extension}>
              {extension?.toUpperCase()}
            </Select.Option>
          ))}
        </Select>
      )}
      {type === onboardingInputTypes.SELECT_ICONS && (
        <Select value={inputRealValue} onChange={onInputChange} {...focusProps}>
          {icons.map((icon) => (
            <Select.Option key={`ONBOARDING_INPUT_ICON_OPTION_${icon}`} value={icon}>
              <Icon icon={icon} className="mr-1" size="sm" /> {icon}
            </Select.Option>
          ))}
        </Select>
      )}
      {type === onboardingInputTypes.SELECT_INPUT_TYPES && (
        <Select
          value={inputRealValue}
          onChange={(value) => {
            setInputTypeSelected(value);
            onInputChange(value);
          }}
          {...focusProps}
        >
          {inputTypes?.map(({ value, label }) => (
            <Select.Option
              key={`ONBOARDING_INPUT_TYPE_OPTION_${onboardingPropsParserTranslator(value, onboardingLanguage)}`}
              value={onboardingPropsParserTranslator(value, onboardingLanguage)}
            >
              {onboardingPropsParserTranslator(label, onboardingLanguage)}
            </Select.Option>
          ))}
        </Select>
      )}
      {type === onboardingInputTypes.SWITCH && (
        <Switch checked={inputRealValue} onChange={(checked) => onInputChange(checked)} {...focusProps} />
      )}
      {type === onboardingInputTypes.SWITCH_OPTION && (
        <RequiredSwitchOption
          checked={inputRealValue}
          label={t("src.components.elementEditor.customizableElements.general.required", "Obrigatório")}
          onChange={(checked) => onInputChange(checked)}
          inputTypeSelected={inputTypeSelected}
          {...focusProps}
        />
      )}
      {type === onboardingInputTypes.DROP_DOWN && (
        <DropDown
          {...{
            onInputChange,
            stepIndex,
            ...rest,
            ...focusProps,
          }}
        />
      )}
      {type === onboardingInputTypes.OBJECT_LIST_EDITOR && (
        <ObjectListEditor
          {...{
            inputValue: inputRealValue,
            onInputChange,
            property: inputProperty,
            isRootProperty,
            stepIndex,
            ...rest,
          }}
        />
      )}
      {type === onboardingInputTypes.SMART_LIST && (
        <SmartOptionsList
          {...{
            inputValue: inputRealValue,
            onInputChange,
            property: inputProperty,
            isRootProperty,
            stepIndex,
            ...rest,
          }}
        />
      )}
      {type === onboardingInputTypes.OBJECT_LIST_EDITOR_DEFAULT_FIELDS && (
        <ObjectListEditorByDefaultFields
          {...{
            inputValue: inputRealValue,
            onInputChange,
            property,
            isRootProperty,
            stepIndex,
            ...rest,
          }}
        />
      )}
      {type === onboardingInputTypes.RULES_LIST && (
        <RulesList inputValue={inputRealValue} onInputChange={onInputChange} />
      )}
      {type === onboardingInputTypes.ERRORS_LIST && (
        <ErrorsList
          {...{
            errors: rest?.errors,
            stepIndex,
            ...rest,
          }}
        />
      )}
      {type === onboardingInputTypes.MULTIPLE_CHECKBOX && (
        <Checkbox.Group value={inputRealValue} onChange={onInputChange}>
          {rest?.options?.map((option, i) => (
            <Row key={`ONBOARDING_INPUT_OPTION_${i}`}>
              <Checkbox value={valueKey ? option[valueKey] : option}>
                {labelKey ? onboardingPropsParserTranslator(String(option[labelKey]), onboardingLanguage) : option}
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>
      )}
    </>
  );
};

OnboardingInput.Text = (props) => <OnboardingInput type={onboardingInputTypes.TEXT} {...props} />;
OnboardingInput.TextWithVariable = (props) => (
  <OnboardingInput type={onboardingInputTypes.TEXT_WITH_VARIABLE} {...props} />
);
OnboardingInput.Number = (props) => <OnboardingInput type={onboardingInputTypes.NUMBER} {...props} />;
OnboardingInput.TextArea = (props) => <OnboardingInput type={onboardingInputTypes.TEXT_AREA} {...props} />;
OnboardingInput.Image = (props) => <OnboardingInput type={onboardingInputTypes.IMAGE} {...props} />;
OnboardingInput.Color = (props) => <OnboardingInput type={onboardingInputTypes.COLOR} {...props} />;
OnboardingInput.Select = (props) => <OnboardingInput type={onboardingInputTypes.SELECT} {...props} />;
OnboardingInput.SelectMultiple = (props) => <OnboardingInput type={onboardingInputTypes.SELECT_MULTIPLE} {...props} />;
OnboardingInput.SelectIcons = (props) => <OnboardingInput type={onboardingInputTypes.SELECT_ICONS} {...props} />;
OnboardingInput.SelectInputTypes = (props) => (
  <OnboardingInput type={onboardingInputTypes.SELECT_INPUT_TYPES} {...props} />
);
OnboardingInput.DropDown = (props) => <OnboardingInput type={onboardingInputTypes.DROP_DOWN} {...props} />;
OnboardingInput.Switch = (props) => <OnboardingInput type={onboardingInputTypes.SWITCH} {...props} />;
OnboardingInput.ObjectListEditor = (props) => (
  <OnboardingInput type={onboardingInputTypes.OBJECT_LIST_EDITOR} {...props} />
);
OnboardingInput.ObjectListEditorByDefaultFields = (props) => (
  <ObjectListEditorByDefaultFields type={onboardingInputTypes.OBJECT_LIST_EDITOR_DEFAULT_FIELDS} {...props} />
);
OnboardingInput.RulesList = (props) => <OnboardingInput type={onboardingInputTypes.RULES_LIST} {...props} />;
OnboardingInput.ErrorsList = (props) => <OnboardingInput type={onboardingInputTypes.ERRORS_LIST} {...props} />;
OnboardingInput.RequiredMaxLengthInput = (props) => (
  <RequiredMaxLengthInput type={onboardingInputTypes.MAX_LENGTH} {...props} />
);
OnboardingInput.RequiredSwitchOption = (props) => (
  <RequiredSwitchOption type={onboardingInputTypes.SWITCH_OPTION} {...props} />
);

export default OnboardingInput;
