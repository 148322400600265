import {
  validateDuplicatedStepsInTheSameFlow,
  validateDuplicatedTemplateFlowNames,
  validateSmartChoiceFlows,
  validateSmartChoiceShouldHaveDocumentType,
} from "@utils/template-validations/steps-validations/flow";
import { hasPowerOfAttorney } from "@utils/template-validations/steps-validations/onboarding-steps/attorney";
import {
  cameraAccessStepMustBeBeforeDDandPFLSteps,
  templateHasNotDDorPFLStepCameraAccessShouldNotBeEnabled,
  templateShouldHasCameraAccessIfDDorPFL,
} from "@utils/template-validations/steps-validations/onboarding-steps/camera-access";
import {
  companyDataStepMustBeBeforeCompanyAddressStep,
  companyDataStepMustExist,
} from "@utils/template-validations/steps-validations/onboarding-steps/company-data";
import { customFormAndItsFieldsHaveIdentifier } from "@utils/template-validations/steps-validations/onboarding-steps/custom-form";
import {
  dataConfirmationMustHasPhoneNumberFieldInCaseOfPhoneNumberStep,
  dataConfirmationStepMustBeInCaseOfEmailValidationStep,
  dataConfirmationStepMustBeInCaseOfPhoneNumberValidationStep,
  dataConfirmationStepMustHaveEmailFieldInCaseOfEmailValidationStep,
  dataConfirmationStepShouldBeAfterDDStep,
} from "@utils/template-validations/steps-validations/onboarding-steps/data-confirmation";
import {
  documentTypeStepHasDocumentTypeAny,
  documentTypeStepMustBeBeforeDDStep,
  documentTypeStepMustHasAtLeastOneDocumentType,
  documentTypeStepMustHaveAtLeastOneRequiredUseTerms,
  templateShouldHaveDocumentTypeOrDocumentIssuedCountryIfDDIsEnabled,
} from "@utils/template-validations/steps-validations/onboarding-steps/document-type";
import { PFPFDataStepMustHaveEmailOrPhoneNumberField } from "@utils/template-validations/steps-validations/onboarding-steps/pf-pf-data";
import { qsaStepHasBothFlagsOn } from "@utils/template-validations/steps-validations/onboarding-steps/qsa";
import {
  sendDocumentTypeStepMustBeAfterDocumentTypeStep,
  sendDocumentTypeStepMustBeBeforeDDStep,
  templateHasNotDDsendDocumentTypeMustNotBeEnabled,
} from "@utils/template-validations/steps-validations/onboarding-steps/send-document-type";
import { checkIdenticalOTHERFieldsTitleAtUploadDocumentsStep } from "@utils/template-validations/steps-validations/onboarding-steps/upload-documents";
import { usingTermsStepMustHaveAtLeastOneRequiredUseTerms } from "@utils/template-validations/steps-validations/onboarding-steps/using-terms";
import {
  filterFlowsStepsIndexes,
  templateStepsOrderValidationsRules,
  validateIfStepHasSpecificFieldBasedOnSecondaryStep,
  validateStepThatRequiresAnotherStep,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "@utils/template-validations/steps-validations/utils";

const stepsValidations = {
  CAMERA_ACCESS: {
    cameraAccessStepMustBeBeforeDDandPFLSteps,
    templateHasNotDDorPFLStepCameraAccessShouldNotBeEnabled,
    templateShouldHasCameraAccessIfDDorPFL,
    validateSmartChoiceFlows,
  },
  COMPANY_DATA: {
    companyDataStepMustBeBeforeCompanyAddressStep,
    companyDataStepMustExist,
  },
  CUSTOM_FORM: {
    customFormAndItsFieldsHaveIdentifier,
  },
  DATA_CONFIRMATION: {
    dataConfirmationStepMustBeInCaseOfEmailValidationStep,
    dataConfirmationStepMustBeInCaseOfPhoneNumberValidationStep,
    dataConfirmationStepMustHaveEmailFieldInCaseOfEmailValidationStep,
    dataConfirmationMustHasPhoneNumberFieldInCaseOfPhoneNumberStep,
    dataConfirmationStepShouldBeAfterDDStep,
  },
  DOCUMENT_TYPE: {
    documentTypeStepHasDocumentTypeAny,
    documentTypeStepMustBeBeforeDDStep,
    documentTypeStepMustHasAtLeastOneDocumentType,
    documentTypeStepMustHaveAtLeastOneRequiredUseTerms,
    templateShouldHaveDocumentTypeOrDocumentIssuedCountryIfDDIsEnabled,
  },
  USING_TERMS: {
    usingTermsStepMustHaveAtLeastOneRequiredUseTerms,
  },
  SEND_DOCUMENT_TYPE: {
    sendDocumentTypeStepMustBeBeforeDDStep,
    sendDocumentTypeStepMustBeAfterDocumentTypeStep,
    templateHasNotDDsendDocumentTypeMustNotBeEnabled,
  },
  PF_PF_DATA: {
    PFPFDataStepMustHaveEmailOrPhoneNumberField,
  },
  UPLOAD_DOCUMENTS: {
    checkIdenticalOTHERFieldsTitleAtUploadDocumentsStep,
  },
  UTILS: {
    filterFlowsStepsIndexes,
    templateStepsOrderValidationsRules,
    validateIfStepHasSpecificFieldBasedOnSecondaryStep,
    validateStepThatRequiresAnotherStep,
    validateTemplateStepsOrderBasedOnEachFlow,
  },
  FLOW: {
    validateDuplicatedStepsInTheSameFlow,
    validateDuplicatedTemplateFlowNames,
    validateSmartChoiceShouldHaveDocumentType,
  },
  QSA: {
    qsaStepHasBothFlagsOn,
  },
  ATTORNEY: {
    hasPowerOfAttorney,
  },
};

export default stepsValidations;
